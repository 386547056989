import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "../dom/Spinner";
import PropTypes from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { KEYS } from "../enums/KeysIndexes";
import { ElementsContext } from "../enums/ElementsContext";

const axios = require("axios").default;

export const PersonsSelectableList = props => {

    const [errors, setErrors] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState(props.prevPerson);
    const [isLoaded, setLoaded] = useState(false);

    useEffect(() => {
        getPersonList(searchValue);
    }, [searchValue]);

    useEffect(() => {
    });

    useEffect(() => {
        if (persons.length <= 0 && isLoaded && searchValue) {
            setErrors(["Не обнаружены физлица соответствующие запросу!"]);
        }
    }, [persons]);

    const getPersonList = (searchValue) => {

        if (searchValue.length === 0) {
            setPersons([]);
            setLoaded(true);
            return;
        } else if (searchValue.length <= 2) {
            setLoaded(true);
            return false;
        }

        setLoaded(false);
        let apiUrl = "/api/persons/";
        let filter = {
            "filter": "Y",
            "f LIKE": `%${searchValue}%`,
        };
        axios.get(apiUrl, {
            params: filter,
        })
            .then((response) => {
                setErrors([]);
                let filteredPersons = response.data.filter((element) => {
                    if (props.context === ElementsContext.STUDENT) {
                        return !element.student;
                    } else if (props.context === ElementsContext.TEACHER) {
                        return !element.teacher;
                    } else if (props.context === ElementsContext.USER) {
                        return !element.user;
                    }
                    return element;
                });
                setLoaded(true);
                setPersons(filteredPersons);
            })
            .catch((error) => {
                setLoaded(true);
                setErrors(error);
                setPersons([]);
            });
    };

    const onPersonSelect = (event) => {
        event.preventDefault();
        if (event.type === "keydown" && event.keyCode !== KEYS.ENTER) {
            return false;
        }
        const item = event.target,
            personId = item.dataset["personId"],
            selectedPerson = persons.find((el) => {
                return el.ID === personId;
            });
        setPerson(selectedPerson);
        props.onPersonSelect(personId);
    };

    const setValue = (event) => {
        const target = event.target;
        setSearchValue(target.value);
    };

    const handleInputKeyDown = (event) => {
        if (!isLoaded && persons.length <= 0) {
            return false;
        }
        let listGroupItems = document.getElementsByClassName("list-group-item");
        switch (event.keyCode) {
            case KEYS.DOWN:
                listGroupItems[0].focus();
                break;
            case KEYS.UP:
                listGroupItems[listGroupItems.length - 1].focus();
                break;
        }
        for (const item of listGroupItems) {
            item.addEventListener("keydown", navigateList);
            item.addEventListener("keydown", onPersonSelect);
        }
    };

    const navigateList = (event) => {
        if (!isLoaded && persons.length <= 0) {
            return false;
        }
        let nextItem,
            listGroupItems = document.getElementsByClassName("list-group-item");
        const thisItem = event.target;
        switch (event.keyCode) {
            case KEYS.DOWN:
                nextItem = thisItem.nextSibling;
                break;
            case KEYS.UP:
                nextItem = thisItem.previousSibling;
                break;
        }
        for (const listGroupItem of listGroupItems) {
            listGroupItem.blur();
        }
        if (nextItem) {
            nextItem.focus();
        }
    };

    const getPersonString = (person) => {
        let personString = `${person.fio}, ДР ${person.birthdate}`;
        if (person.email) {
            personString += `, email: ${person.email}`;
        }
        if (person.student) {
            personString += `. (Слушатель ID ${person.student.ID})`;
        }
        if (person.teacher) {
            personString += `. (Педагог ID ${person.teacher.ID})`;
        }
        if (person.wishes) {
            personString += `. (Желающий ID ${person.wishes.ID})`;
        }
        if (person.user) {
            personString += `. (Пользователь ID ${person.user.ID})`;
        }
        return personString;
    };

    const clearInput = (event) => {
        event.preventDefault();
        setSearchValue("");
        setErrors([]);
    };

    return (
        <Fragment>
            <div className="input-group mb-3">
                <input type="text"
                    className="form-control"
                    onChange={setValue}
                    onKeyDown={handleInputKeyDown}
                    value={searchValue}
                    aria-describedby="searchHelpBlock"
                    placeholder="искать физлицо по фамилии"
                    aria-label="искать физлицо по фамилии" />
                <button className="btn btn-outline-secondary" type="button" onClick={clearInput}>X</button>
            </div>
            {errors.length > 0 ? <ErrorContainer arErrors={errors} /> : ""}
            {!isLoaded ? <Spinner /> : (
                <div className={"list-group shadow"}>
                    {
                        persons.map((person, index) => (
                            <a key={person.ID}
                                href={"#"}
                                className={"list-group-item list-group-item-action"}
                                data-person-id={person.ID}
                                onClick={onPersonSelect}
                                title={"выбрать это физлицо"}>
                                {getPersonString(person)}
                            </a>
                        ),
                        )
                    }
                </div>
            )}
        </Fragment>
    );

};

PersonsSelectableList.propTypes = {
    prevPerson: PropTypes.object,
    context: PropTypes.number,
    onCancelBtnClick: PropTypes.func,
    onPersonSelect: PropTypes.func,
};
