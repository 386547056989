import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { Spinner } from "../dom/Spinner";
import { Utils } from "../Utils";
import { Select } from "../dom/Select";

const axios = require('axios').default;
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserForm = props => {

    const formHeightRef = useRef(null)

    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {
            htmlElRef.current && htmlElRef.current.focus();
        };

        return [htmlElRef, setFocus];
    };

    const [errors, setErrors] = useState([]);
    const [validateErrors, setValidateErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [loginIsTaken, setLoginIsTaken] = useState(false);
    const [emailIsTaken, setEmailIsTaken] = useState(false);
    const [user, setUser] = useState(props.user);
    const [person, setPerson] = useState(props.person);
    const [roles, setRoles] = useState(props.roles);
    const [loginRef, setLoginFocus] = useFocus();
    const [emailRef, setEmailFocus] = useFocus();
    const [needSave, setNeedSave] = useState(false);
    const [formHeight, setFormHeight] = useState(0)

    useEffect(() => {
    });

    useEffect(() => {
        if (formHeightRef) {
            setFormHeight(formHeightRef.current.clientHeight)
        }
    }, []);

    useEffect(() => {
        let cleanupFunction = false;

        function rolesCallback(roles) {
            if (!cleanupFunction && roles) {
                setRoles(roles);
            }
        }

        if (!roles.length) {
            getRoles(rolesCallback);
        }
        return () => {
            cleanupFunction = true;
        };
    }, []);

    useEffect(() => {
        setNeedSave(true);
    }, [user]);

    useEffect(() => {
        if (props.person?.ID) {
            let newUserData = {
                person: props.person,
                person_id: props.person.ID,
                login: user.login ? user.login : Utils.setLoginFromFio(props.person.f, props.person.i, props.person.o),
                email: user.email ? user.email : props.person.email,
            };
            setPerson(props.person);
            setUser({ ...user, ...newUserData });
        }
    }, [props.person]);

    const getRoles = (callback) => {
        axios.get('/api/roles/')
            .then((response) => {
                callback(response.data);
            })
            .catch((error) => {
                setErrors(errors => [...errors, error.response.data]);
                callback(false);
            });
    };

    const handleRoleChange = (e) => {
        const { value } = e.target;
        let errors = [];
        setValidateErrors([]);
        let role = roles.find((element) => {
            return element.ID == value;
        });
        if (role && role.admin == 1 && app.session.appa != "admin") {
            errors.push("Только администратор системы может назначать нового администратора!");
            setValidateErrors(errors);
            return;
        }
        setUser({ ...user, role_id: value });
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setUser({ ...user, [id]: value });
    };

    const validateUser = () => {
        let errors = [];
        setValidateErrors([]);
        if (!user.login) {
            errors.push("Не установлен логин!");
        }
        if (!user.email) {
            errors.push("Не установлен эл. адрес!");
        } else if (!Utils.validateEmail(user.email)) {
            errors.push("Не корректный эл. адрес!");
        }
        if (!user.role_id) {
            errors.push("Не установлена роль пользователя!");
        }
        if (props.context && !person?.ID) {
            errors.push("Не установлено физ лицо для записи пользователя!");
        }
        if (loginIsTaken) {
            errors.push("Выбранный логин занят!");
        }
        if (emailIsTaken) {
            errors.push("Выбранный email занят!");
        }
        setValidateErrors(errors);
        return errors.length === 0;
    };

    const saveUser = (event) => {
        event.preventDefault();
        setLoading(true);
        if (!validateUser()) {
            setLoading(false);
            return false;
        }
        axios.put('/api/users/', { element: user },
        )
            .then((response) => {
                setLoading(false);
                props.onUserChange?.(response.data);
            })
            .catch((error) => {
                setErrors(errors => error.response.data.errors);
                setLoading(false);
            });
    };

    const removeUser = async (event) => {
        event.preventDefault();
        const confirmRes = await app.getConfirm("Будет удалён пользователь! Действительно удалить?");
        if (!confirmRes) {
            return false;
        }
        if (!user.ID) {
            return false;
        }
        setLoading(true);
        axios.delete(`/api/users/${user.ID}`)
            .then((response) => {
                setLoading(false);
                setUser({});
                props.onUserChange?.(null);
            })
            .catch((error) => {
                setErrors(errors => error.response.data.errors);
                setLoading(false);
            });
    };

    const checkField = (field, value) => {
        if (!needSave || !value || value.length === 0) {
            return false;
        }
        let params = { "filter": "Y" };
        params[field] = value;

        axios.get('/api/users/',
            {
                params: params,
            },
        )
            .then((response) => {
                let isTaken = response.data.length > 0;
                switch (field) {
                    case "email":
                        setEmailIsTaken(isTaken);
                        break;
                    case "login":
                        setLoginIsTaken(isTaken);
                        break;
                }
            })
            .catch((error) => {
                setErrors(errors => error.response.data.errors);
            });
    };

    const AddOnEmailButton = () => {
        const btnClass = emailIsTaken ? "input-group-text text-danger" : "input-group-text text-success";
        const btnContent = emailIsTaken ? String.fromCharCode(215) : String.fromCharCode(10003);
        const btnTitle = emailIsTaken ? "Email занят" : "Email не занят";
        return (
            <span className={btnClass} title={btnTitle}>{btnContent}</span>
        );
    };

    const AddOnLoginButton = () => {
        const btnClass = loginIsTaken ? "input-group-text text-danger" : "input-group-text text-success";
        const btnContent = loginIsTaken ? String.fromCharCode(215) : String.fromCharCode(10003);
        const btnTitle = loginIsTaken ? "Логин занят" : "Логин свободен";
        return (
            <span className={btnClass} title={btnTitle}>{btnContent}</span>
        );
    };

    return (
        <Fragment>
            {isLoading
                ? <div className={"p-5"} style={{ "height": formHeight }}><Spinner /></div>
                : <div ref={formHeightRef}>

                    {errors.length > 0 ?
                        <ErrorContainer arErrors={errors} />
                        :
                        ""
                    }
                    {props.context && props.showHeader ?
                        <div className={"mt-3"}>
                            {user.ID ?
                                <h5>Редактирование пользователя с ID {user.ID}</h5>
                                :
                                <h5>Новый пользователь</h5>
                            }
                        </div>
                        :
                        ""
                    }

                    <div className={"row mb-2"}>
                        <div className={"col"}>
                            {user.ID ?
                                <Fragment>
                                    {user.lastActivity ?
                                        <span className={"text-muted small"}>Последняя авторизация {user.lastActivity}</span>
                                        :
                                        <span className={"text-muted small"}>Не авторизовался ранее</span>
                                    }
                                </Fragment>
                                :
                                ""
                            }
                        </div>
                    </div>
                    <div className={"row mb-3"}>
                        <Fragment>
                            <div className={"form-group col-12 col-md"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <label className={"d-block"} htmlFor={"login"}>Логин
                                            <span className="required"> </span>
                                        </label>
                                    </div>
                                    <div className={"col text-end"}>
                                        {loginIsTaken ?
                                            <span className={"text-danger"}>
                                                логин занят!
                                            </span>
                                            : ""}
                                    </div>
                                </div>
                                <div className={"input-group"}>
                                    <input className="form-control"
                                        id="login"
                                        onChange={handleChange}
                                        onBlur={e => {
                                            checkField(e.target.id, e.target.value);
                                        }}
                                        autoComplete={"off"}
                                        type="text"
                                        value={user.login || ""}
                                        ref={loginRef}
                                        aria-label="Логин"
                                        aria-describedby="button-addon-login" />

                                    <AddOnLoginButton />

                                    <small id="loginHelp" className="form-text text-muted">
                                        <ul>
                                            <li>
                                                Пароль для пользователя будет установлен автоматически. При
                                                авторизации
                                                необходимо будет запросить его восстановление по email и по
                                                ссылке
                                                поменять
                                                пароль!
                                            </li>
                                            <li>
                                                При смене логина также будет сгенерирован новый пароль.
                                            </li>
                                        </ul>
                                    </small>
                                </div>
                            </div>

                            <div className={"form-group col-12 col-md"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <label className={"d-block"} htmlFor={"login"}>Email
                                            <span className="required"> </span>
                                        </label>
                                    </div>
                                    <div className={"col text-end"}>
                                        {emailIsTaken ?
                                            <span className={"text-danger"}>
                                                email занят!
                                            </span>
                                            : ""}
                                    </div>
                                </div>
                                <div className={"input-group"}>
                                    <input className="form-control"
                                        id="email"
                                        onChange={handleChange}
                                        onBlur={e => {
                                            checkField(e.target.id, e.target.value);
                                        }}
                                        autoComplete={"off"}
                                        type="text"
                                        value={user.email || ""}
                                        ref={emailRef}
                                        aria-label="Email"
                                        aria-describedby="button-addon-email" />

                                    <AddOnEmailButton />

                                    <small id="emailHelp" className="form-text text-muted">
                                        Этот email будет использоваться для взаимодействия со слушателем как
                                        с
                                        пользователем дневника
                                    </small>
                                </div>
                            </div>

                            <div className={"form-group col-12 col-md"}>
                                <Select
                                    isRequired={true}
                                    id="role_id"
                                    onChange={handleRoleChange}
                                    label={"Роль пользователя"}
                                    defaultOption={"Выбрать роль"}
                                    options={roles}
                                    selected={user.role_id | ""} />
                                <small id="emailHelp" className="form-text text-muted">
                                    Установите роль пользователя - она будет определять его права при работе
                                    с
                                    дневником
                                </small>
                            </div>

                            <div className={"form-group col-12 text-end"}>
                                <div className={"row align-items-center"}>
                                    <div className={"col-12 col-md"}>
                                        {validateErrors.length > 0 ?
                                            <ErrorContainer
                                                arErrors={validateErrors}
                                                small={true}
                                                showClose={false} />
                                            : ""
                                        }
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <button
                                            onClick={saveUser}
                                            title={"Сохранить данные пользователя"}
                                            disabled={!needSave}
                                            className={"btn btn-primary me-2 btn-sm"}>
                                            Сохранить
                                        </button>
                                        <button
                                            onClick={removeUser}
                                            title={"Закрыть пользователю доступ"}
                                            className={"btn btn-danger me-2 btn-sm"}>
                                            Удалить
                                        </button>
                                        <button
                                            onClick={props.onCancelButtonClick}
                                            title={"Отменить редактирование"}
                                            className={"btn btn-outline-primary btn-sm"}>
                                            Отменить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>

                </div>
            }
        </Fragment>
    );
};

UserForm.propTypes = {
    user: PropTypes.object,
    person: PropTypes.object,
    roles: PropTypes.array,
    showHeader: PropTypes.bool,
    context: PropTypes.number,
    onCancel: PropTypes.func,
    onUserChange: PropTypes.func,
    onCancelButtonClick: PropTypes.func,
};
