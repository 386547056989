import React, {Fragment} from "react";
import PropTypes         from "prop-types";

export const StudentLinkView = props => {
    const element = props.element;
    return (
        <div>
            <a href={`/students/${element.ID}/`}
               title={"Просмотреть данные слушателя"}>
                ID {element.ID} - {element.person?.fio}
            </a>
            <a className={"ms-3"}
               role={"button"}
               href={`/students/${element.ID}/edit/`}>
                <i className="fas fa-pencil-alt small text-secondary"></i>
            </a>
        </div>
    );
};

StudentLinkView.propTypes = {
    element: PropTypes.object,
};
