import React from "react";
import PropTypes         from "prop-types";

export const WishesListElementView = props => {
    const element = props.element;
    return (
            <li>
                <a href={`/wishes/${element}/`}
                   data-modal-size="xl"
                   data-in-modal="Y"
                   title="просмотреть данные по заявке на обучение">
                    ID {element.program_id} - {element.display_time_insert} - {element.program?.name}
                </a>
            </li>
    );
};

WishesListElementView.propTypes = {
    element: PropTypes.object,
};
