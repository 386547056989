import React, {Fragment} from "react";
import PropTypes               from "prop-types";
import {WishesListElementView} from "./WishesListElementView";

export const WishesListView = props => {
    return (
        <Fragment>
            {
                (props.wishesList && props.wishesList.length > 0) ? (
                        <div>
                            <h4 className={"card-title"}>Желает обучаться</h4>
                            <ul>
                                {
                                    props.wishesList.map((wishes) => {
                                        return (
                                            <WishesListElementView key={wishes.ID} element={wishes}/>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    )
                    :
                    ""
            }
        </Fragment>
    );
};

WishesListView.propTypes = {
    wishesList: PropTypes.array,
};
