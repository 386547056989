import {PersonPhoto}                          from "./PersonPhoto";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {Spinner}                              from "../dom/Spinner";
import {StudentLinkView}                      from "./StudentLinkView";
import {WishesListView}                       from "./WishesListView";
import {ElementsContext}                      from "../enums/ElementsContext";
import {TeacherView}                          from "./TeacherView";
import {UserSection}                          from "./UserSection";

const axios = require("axios").default;

export const PersonView = props => {
    const [person, setPerson] = useState(props.person);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [wishes, setWishes] = useState([]);
    const [teacher, setTeacher] = useState({});
    const [student, setStudent] = useState(props.person.student || {});

    useEffect(() => {
    });

    useEffect(() => {
        if (!props.context) {
            loadData();
        }
    }, []);

    const loadData = () => {
        // получим программы, источники информации и данные по элементу
        const params = {params: {filterPerson_id: person.ID}};
        setLoading(true);
        const wishesRequest = () => {
            return axios.get('/api/wishes/', params);
        };
        const teachersRequest = () => {
            return axios.get('/api/teachers/', params);
        };
        const studentRequest = () => {
            return axios.get('/api/students/', params);
        };
        axios.all([wishesRequest(), teachersRequest(), studentRequest()])
             .then(axios.spread((wishesRequest, teachersRequest, studentRequest) => {
                 setWishes(wishesRequest?.data);
                 setTeacher(teachersRequest?.data?.shift());
                 setStudent(studentRequest?.data?.shift());
                 setLoading(false);
             }))
             .catch((error) => {
                 setLoading(false);
                 setErrors(errors => [...errors, error.response?.data]);
             });
    };

    const WishesBlock = props => {
        return (
            <Fragment>
                {wishes ?
                    <WishesListView wishesList={wishes}/>
                    :
                    ""
                }
                <div className={"text-center"}>
                    <a className={"btn btn-success btn-sm"}
                       href={`/wishes/add/?person_id=${person.ID}`}
                       title={"Оформить заявку на обучение"}>
                        Оформить заявку на обучение
                    </a>
                </div>
            </Fragment>
        );
    };

    const StudentBlock = props => {
        return (
            <Fragment>
                <h4 className={"card-title"}>Слушатель</h4>
                {student ?
                    <StudentLinkView element={student}/>
                    :
                    <Fragment>
                        <p className={"text-muted small"}>Слушатель еще не оформлен.</p>
                        <a
                            className={"btn btn-primary btn-sm"}
                            href={`/students/add/?person_id=${person.ID}`}
                            title={"Оформить слушателя на это физлицо"}>
                            Оформить слушателя
                        </a>
                    </Fragment>
                }
            </Fragment>
        );
    };

    return (
        <div className={"card shadow-sm h-100 mb-3"}>
            <div className="card-body">

                {person.ID ?

                    <div className="row mb-3">
                        <div className="col">
                            <PersonPhoto
                                gender={person.gender || ""}
                                photoSrc={person.photo || ""}/>
                        </div>
                        <div className="col-md-8">
                            <h5>ID {person.ID}. {person.fio}</h5>
                            <p className="card-text">
                                {person.phone ? person.phone : "телефон не указан"}
                                &nbsp;|&nbsp;
                                {person.email ? person.email : "email не указан"}
                            </p>
                            <p>
                                Пол: {person.genderLocalName}, Д.р: {person.birthdateDisplay} г.р.
                            </p>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col text-center">
                            <h4 className={"text-danger"}>Физлицо не установлено!</h4>
                        </div>
                    </div>
                }

                {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

                {!props.context ?
                    <Fragment>
                        <div className={"row"}>
                            <div className={"col-12 mb-2"}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        {isLoading ? <Spinner small={true}/> : <WishesBlock/>}
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 mb-2"}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        {isLoading ? <Spinner small={true}/> : <StudentBlock/>}
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 mb-2"}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        {isLoading ?
                                            <Spinner small={true}/> :
                                            <Fragment>
                                                {teacher ?
                                                    <TeacherView
                                                        context={ElementsContext.PERSON}
                                                        teacher={teacher}/>
                                                    :
                                                    <div className={"text-center"}>
                                                        <a
                                                            className={"btn btn-primary btn-sm"}
                                                            href={`/teachers/add/?person_id=${person.ID}`}
                                                            title={"Оформить как педагога"}>
                                                            Оформить как педагога
                                                        </a>
                                                    </div>
                                                }
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"col-12 mb-2"}>
                                <div className="card h-100">
                                    <div className="card-body">
                                        {isLoading ?
                                            <Spinner small={true}/> :
                                            <UserSection
                                                user={person?.user}
                                                person={person}
                                                context={ElementsContext.PERSON}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    : ""
                }
            </div>
            {person.ID && props.editable ?
                <div className="card-footer text-end">
                    <a className={"btn btn-success btn-sm me-2"}
                       role={"button"}
                       title={"Изменить данные слушателя в новом окне"}
                       href={`/persons/${person.ID}/edit/`}>
                        <i className="fas fa-pencil-alt me-2"></i>
                        Изменить
                    </a>
                </div>
                : ""
            }
        </div>
    );
};

PersonView.propTypes = {
    context: PropTypes.number,
    editable: PropTypes.bool,
    person: PropTypes.object,
};
