import React, {Fragment, useEffect, useRef, useState} from "react";
import PropTypes                                      from "prop-types";
import {Spinner}                                      from "../dom/Spinner";
import {PersonForm}                                   from "./PersonForm";
import {PersonsSelectableList}                        from "./PersonsSelectableList";
import {PersonView}                                   from "./PersonView";
import {ErrorContainer}                               from "../dom/ErrorContainer";
import {FieldsetHeader}                               from "../dom/FieldsetHeader";
import {ButtonPrimary}                                from "../dom/ButtonPrimary";
import {HelpButton}                                   from "../dom/HelpButton";

const axios = require("axios").default;

const STAGES = {
    NO_SELECT: 0,
    VIEW: 1,
    SELECT: 2,
    EDIT: 3,
    NEW: 4,
};

export const PersonSection = props => {
    let RenderPersonContent, RenderPersonButtons, headerText;
    const [person, setPerson] = useState(props.person);
    const [personId, setPersonId] = useState(props.personId);
    const [prevPersonId, setPrevPersonId] = useState(props.personId);
    const [isLoading, setLoading] = useState(false);
    const [stage, setStage] = useState(props.edit ? STAGES.EDIT : STAGES.SELECT);
    const [needReload, setNeedReload] = useState(false);
    const [errors, setErrors] = useState([]);
    const mountedRef = useRef(true);

    useEffect(() => {
        if (personId) {
            setStage(props.edit ? STAGES.EDIT : STAGES.VIEW);
            loadData();
            return () => {
                mountedRef.current = false;
            };
        }
    }, []);

    useEffect(() => {
        if (needReload) {
            loadData();
        }
    }, [needReload]);

    useEffect(() => {
        if (person) {
            props.onPersonSet?.(person);
        }
    }, [person]);

    useEffect(() => {
    });

    const handleEditButtonClick = (event) => {
        setStage(STAGES.EDIT);
        event.preventDefault();
    };

    const handleSelectButtonClick = (event) => {
        savePrevPerson();
        setStage(STAGES.SELECT);
        event.preventDefault();
    };

    const handleAddButtonClick = (event) => {
        savePrevPerson();
        setStage(STAGES.NEW);
        event.preventDefault();
    };

    const returnOnStage = (event) => {
        switch (stage) {
            case STAGES.EDIT:
                setStage(STAGES.VIEW);
                break;
            case STAGES.NEW:
                if (personId || prevPersonId) {
                    setPersonId(prevPersonId);
                    setStage(STAGES.VIEW);
                } else {
                    setStage(STAGES.SELECT);
                }
                break;
            case STAGES.SELECT:
                if (prevPersonId) {
                    setPersonId(prevPersonId);
                    setNeedReload(true);
                    setStage(STAGES.VIEW);
                } else {
                    setNeedReload(false);
                    setStage(STAGES.NO_SELECT);
                }
                break;
            default:
                setStage(STAGES.VIEW);
                break;
        }
    };

    const savePrevPerson = () => {
        setPrevPersonId(personId);
        setPersonId("");
    };

    const onPersonChange = (personId) => {
        setPersonId(personId);
        setStage(STAGES.VIEW);
        setNeedReload(true);
    };

    const loadData = () => {
        setLoading(true);
        axios.get(`/api/persons/${personId}/`)
             .then((response) => {
                 if (!mountedRef.current) return null;
                 props.onPersonChange?.(response.data);
                 setPerson(response.data);
                 setNeedReload(false);
                 setLoading(false);
                 return response.data;
             })
             .catch((errors) => {
                 setLoading(false);
                 setNeedReload(false);
                 setErrors(errors => [...errors, "Не удалось выполнить запрос!"]);
                 if (app.debug) {
                     throw new Error(errors);
                 }
             });
    };

    if (personId) {
        RenderPersonContent = componentProps => {
            if (stage === STAGES.EDIT) {
                // редактирование персональных данных
                return <PersonForm
                    person={person}
                    editable={true}
                    context={props.context}
                    onCancel={returnOnStage}
                    onPersonChange={onPersonChange}/>;
            } else {
                return (
                    <PersonView
                        context={props.context}
                        person={person || {}}/>
                );
            }
        };
        headerText = `Персональные данные физического лица ID ${personId}`;
    } else {
        RenderPersonContent = componentProps => {
            switch (stage) {
                case STAGES.SELECT:
                    return (
                        <PersonsSelectableList
                            prevPerson={person}
                            context={props.context}
                            onCancelBtnClick={returnOnStage}
                            onPersonSelect={onPersonChange}/>
                    );
                case STAGES.NEW:
                    // создание нового физлица
                    return <PersonForm
                        person={{}}
                        context={props.context}
                        onCancel={returnOnStage}
                        onPersonChange={onPersonChange}/>;
                default:
                    return (
                        <div className="row">
                            <div className="col-12 text-center text-danger py-3">
                                <div className={"alert alert-info"}>
                                    Необходимо выбрать или создать физическое лицо (персональные данные) для
                                    пользователя!
                                </div>
                            </div>
                        </div>
                    );
            }
        };
        headerText = `Персональные данные физического лица`;
    }

    if (stage === STAGES.VIEW || stage === STAGES.EDIT || stage === STAGES.NO_SELECT || stage === STAGES.SELECT) {
        RenderPersonButtons = props => {
            return (
                <Fragment>
                    {personId ? (
                        <ButtonPrimary
                            text={"Редактировать"}
                            inModal={false}
                            inModalSize={"xl"}
                            onClick={handleEditButtonClick}
                        />
                    ) : ""}
                    {stage !== STAGES.SELECT ? (
                        <ButtonPrimary
                            text={"Выбрать"}
                            onClick={handleSelectButtonClick}
                        />
                    ) : ""}
                    <ButtonPrimary
                        text={"Новое физлицо"}
                        onClick={handleAddButtonClick}
                    />
                    {stage === STAGES.SELECT ? (
                        <Fragment>
                            {personId || prevPersonId ? (
                                <div className={"float-end"}>
                                    <ButtonPrimary
                                        text={"<<< Назад"}
                                        onClick={returnOnStage}
                                    />
                                </div>
                            ) : ""}
                        </Fragment>
                    ) : ""}
                </Fragment>
            );
        };
    } else {
        RenderPersonButtons = props => {
            return (
                <Fragment>
                    {personId || prevPersonId ? (
                        <div className={"float-end"}>
                            <ButtonPrimary
                                text={"<<< Назад"}
                                onClick={returnOnStage}
                            />
                        </div>
                    ) : ""}
                </Fragment>
            );
        };
    }

    if (isLoading) {
        return <Spinner/>;
    }

    if (errors.length > 0) {
        return <ErrorContainer arErrors={errors}/>;
    }

    return (
        <Fragment>
            <div className="card mb-3 shadow-sm rounded-lg w-100 p-3">
                <div className="row">
                    <div className="col-11">
                        <FieldsetHeader
                            text={headerText}/>
                        <div className={"float-end"}>
                            <RenderPersonButtons/>
                        </div>
                    </div>
                    <div className="col-1">
                        {!props.disableSelect ? (
                            <HelpButton dataLoadUrl={"person.fields.help"}/>
                        ) : ""}
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 py-3">
                        <RenderPersonContent/>
                    </div>
                </div>

            </div>
        </Fragment>
    );

};

PersonSection.propTypes = {
    disableSelect: PropTypes.bool,
    personId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPersonChange: PropTypes.func,
    onPersonSet: PropTypes.func,
    edit: PropTypes.bool,
    context: PropTypes.number,
};


