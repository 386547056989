import React   from "react";
import {Utils} from "../Utils";

export const Checkbox = ({label, name, selected, onChange}) => (
    <div className="form-check">
        <input type="checkbox"
               name={name}
               className="form-check-input"
               id={"checkBox" + Utils.capitalize(name)}
               checked={selected}
               onChange={onChange}/>
        <label
            className="form-check-label"
            htmlFor={"checkBox" + Utils.capitalize(name)}>
            {label}
        </label>
    </div>

);
