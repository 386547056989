import React     from "react";
import PropTypes from "prop-types";

export const FieldsetHeader = props => (
    <h5 id="personalInfoHeader" className="d-inline-block">
        {props.text}
    </h5>
);

FieldsetHeader.propTypes = {
    text: PropTypes.string,
};
