import React, {Fragment} from "react";

export const Select = props => (
    <Fragment>
        <Fragment>
            {props.label && props.label.length > 0 ?
                <label htmlFor={props.id}>{props.label} {props.isRequired ? (
                    <span className="required"> </span>) : ("")}</label>
                : ""
            }
        </Fragment>
        <select
            className="form-control"
            id={props.id}
            name={props.name}
            value={props.selected}
            required={props.isRequired}
            onChange={props.onChange}>
            <Fragment>
                {props.defaultOption && props.defaultOption.length > 0 ?
                    <option value={""}>{props.defaultOption}</option>
                    : ""
                }
            </Fragment>
            {
                (props.options || []).map((option, index) => (
                        <option key={option.ID} value={option.ID}>{option.name}</option>
                    ),
                )
            }
        </select>
    </Fragment>
);

