import React     from "react";
import PropTypes from "prop-types";

export const ButtonPrimary = props => (
    <button
        onClick={props.onClick}
        title={props.text}
        data-in-modal={props.inModal ? "Y" : "N"}
        data-modal-size={props.inModalSize}
        className={"btn btn-primary me-2 btn-sm"}>
        {props.text}
    </button>
);

ButtonPrimary.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    inModal: PropTypes.bool,
    inModalSize: PropTypes.oneOf(['sm', 'lg', 'xl']),
};
