import React, {Fragment, useEffect, useState} from "react";
import PropTypes                              from "prop-types";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import {Spinner}                              from "../dom/Spinner";

const axios = require("axios").default;

export const TeacherView = props => {
    const [teacher, setTeacher] = useState(props.teacher);
    const [personId, setPersonId] = useState(props.personId);
    const [person, setPerson] = useState(props.person);
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!teacher) {
            loadData();
        }
    }, []);

    const loadData = () => {
        axios.get('/api/teachers/', {
            params: {
                "filter": `Y`,
                "person_id": `${personId || person.ID}`,
            },
        })
             .then((response) => {
                 setTeacher(response.data);
             })
             .catch((error) => {
                 setErrors(errors => [...errors, error.response.data]);
             });
    };

    if (isLoading) {
        return <Spinner/>;
    }

    return (
        <Fragment>
            {teacher ?
                <Fragment>
                    <h4 className={"card-title"}>Педагог</h4>
                    <a href={`/teachers/${teacher.ID}/`} title={`Педагог # ${teacher.ID} ${teacher.fio}`}> # {teacher.ID} {teacher.fio}</a>
                </Fragment>
                : ""}
        </Fragment>
    );
};

TeacherView.propTypes = {
    teacher: PropTypes.object,
    personId: PropTypes.string,
    person: PropTypes.object,
    context: PropTypes.number,
};
